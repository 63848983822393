import React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '../../lib/styles/globalStyles'
import { theme } from '../../lib/styles/theme'
import '../../lib/styles/typography.css'
import FloatingHeader from './floatingHeader/FloatingHeader'
import LeftFloatingBar from './floatingHeader/LeftFloatingBar'
import Footer from './Footer'
import Responsive from './Responsive'
import { withTrans } from '../../i18n/withTrans'
import TopButton from './floatingHeader/TopButton'
import InvertColorsContext from '../../context/InvertColorsContext'

interface ILayoutProps {
	children: React.ReactNode
	blackFooter?: boolean
	blackBackground?: boolean
	medicalPage?: boolean
	isInfo?: boolean
	isDataVoucher?: boolean
	isBrowswerSafariNotMobile?: boolean
}

function Layout({
	children,
	blackFooter,
	blackBackground,
	medicalPage,
	isInfo = true,
	isDataVoucher = false,
	isBrowswerSafariNotMobile,
}: ILayoutProps) {
	const isClient = typeof Window !== 'undefined'
	let url = ''

	if (isClient === true) {
		url = window.location.pathname
	}

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<InvertColorsContext.Consumer>
					{({ itemColor, invertColors }) => {
						return (
							<Block blackBackground={blackBackground}>
								{isDataVoucher ? (
									''
								) : (
									<FloatingHeader blackBackground={blackBackground} />
								)}
								<Responsive isDataVoucher={isDataVoucher}>
									{isDataVoucher ||
									url.includes('datavoucher') ||
									url.includes('solution') ? (
										''
									) : (
										<LeftFloatingBar itemColor={itemColor} />
									)}
									{children}
									<TopButton itemColor={itemColor} />
								</Responsive>
								<Footer
									invertColors={invertColors}
									blackFooter={blackFooter}
									medicalPage={medicalPage}
									isInfo={isInfo}
									isBrowswerSafariNotMobile={isBrowswerSafariNotMobile}
								/>
							</Block>
						)
					}}
				</InvertColorsContext.Consumer>
				<div id="modal-root"></div>
			</ThemeProvider>
		</React.Fragment>
	)
}

export default React.memo(withTrans(Layout))

const Block = styled.div<{ blackBackground?: boolean }>`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: ${(props) =>
		props.blackBackground === true ? 'black' : '#0000'};
`
