import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { window, document } from 'browser-monads'

import Logo from '../../../assets/svg/logo.svg'
import LogoWhite from '../../../assets/svg/logo-white.svg'
import Responsive from '../Responsive'
import RightNavigation from './RightNavigation'
import MenuButton from './MenuButton'
import zIndexes from '../../../lib/styles/zIndexes'
import ConsultRequest from '../../dataVoucher/ConsultRequest'

interface FloatingHeaderProps {
	isDataVoucher?: boolean
	blackBackground?: boolean
}

function FloatingHeader({ isDataVoucher, blackBackground }: FloatingHeaderProps) {
	const [pageY, setPageY] = useState(0)
	const documentRef = useRef(document)

	const handleScroll = () => {
		const { pageYOffset } = window
		setPageY(pageYOffset)
	}

	useEffect(() => {
		documentRef.current.addEventListener('scroll', handleScroll)
		return () => documentRef.current.removeEventListener('scroll', handleScroll)
	}, [pageY])

	const breakpoints = useBreakpoint()
	const SCROLL_VALUE = 30

	return isDataVoucher ? (
		<DataVoucherBlock>
			<Link to="/">
				<Logo />
			</Link>
			<ConsultRequest />
		</DataVoucherBlock>
	) : (
		<Block minimize={pageY > SCROLL_VALUE}>
			<StyledResponsive minimize={pageY > SCROLL_VALUE}>
				<Link to="/">
					{blackBackground === true ? <LogoWhite /> : <Logo />}
				</Link>
				{breakpoints.md ? <MenuButton blackBackground={blackBackground}/> : <RightNavigation blackBackground={blackBackground}/>}
			</StyledResponsive>
		</Block>
	)
}

const Block = styled.div<{ minimize: boolean }>`
	pointer-events: none;
	width: 100%;
	display: flex;
	position: fixed;
	top: 0;
	align-items: center;
	z-index: ${zIndexes.header};
	margin: 0 auto;
	justify-content: space-between;
	padding-top: 72px;
	transition: all 0.3s;

	${down('xs')} {
		padding-top: 0;
	}

	${(props) =>
		props.minimize &&
		css`
			padding-top: 28px;
		`}
	a, li {
		pointer-events: all;
	}
`

const DataVoucherBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (min-width: 1280px) {
		padding-top: 62px;
		padding-left: 115px;
		padding-right: 110px;
		width: 1440px;
		svg {
			width: 296px;
			height: 57px;
		}
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		padding-bottom: 192px;
		padding-right: 32px;
		width: 100%;
		svg {
			width: 179px;
			height: 32px;
		}
	}
	@media (max-width: 688px) {
		padding-left: 22px;
		padding-right: 16px;
		padding-bottom: 12px;
		padding-top: 12px;

		width: 100vw;
		height: 60px;
		border-bottom: solid 1px #eaeaea;
		margin-bottom: 141px;
		svg {
			width: 124px;
			height: 23px;
		}
	}
`

const StyledResponsive = styled(Responsive)<{ minimize: boolean }>`
	display: flex;
	justify-content: space-between;
	> a {
		height: 100%;
		cursor: pointer;

		> svg {
			transition: all 0.3s;
			${down('xs')} {
				width: 158px;
			}

			${(props) =>
				props.minimize &&
				css`
					width: 196px;
				`}
		}
	}
`

export default FloatingHeader
