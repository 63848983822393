import React, { useMemo } from 'react'
import styled from 'styled-components'
import typography, {
	DisplayType,
	HeadingType,
	BodyType,
	TypographyFontWeight,
	getWeightValue,
} from '../../lib/styles/typography'
import { PaletteType } from '../../lib/styles/palette'

export type TypographyAlign = 'left' | 'center' | 'right'

type TypographyProps = {
	children?: React.ReactNode
	color?: PaletteType
	fontWeight?: TypographyFontWeight
	className?: string
	id?: string
	onClick?: React.MouseEventHandler<HTMLElement>
	dataSal?: 'slide-up' | 'slide-down' | 'fade' | 'zoom-in' | 'zoom-out'
	dataSalDelay?: string
	dataSalDuration?: string
}

// display
type DisplayProps = TypographyProps & {
	size: DisplayType
}

const Display = React.memo<DisplayProps>(
	({
		size,
		children,
		color,
		fontWeight,
		className,
		id,
		onClick,
		dataSal,
		dataSalDelay,
		dataSalDuration,
	}) => {
		return (
			<Span
				size={size}
				color={color}
				fontWeight={useMemo(() => getWeightValue(fontWeight), [fontWeight])}
				className={className}
				id={id}
				onClick={onClick}
				data-sal={dataSal}
				data-sal-delay={dataSalDelay}
				data-sal-duration={dataSalDuration}
			>
				{children}
			</Span>
		)
	}
)

type TagProps = {
	color?: PaletteType
	fontWeight?: number
	size?: DisplayType | HeadingType | BodyType
}

const Span = styled.span<
	TagProps & {
		size: DisplayType
		align?: TypographyAlign
		color?: PaletteType
	}
>`
	${(props) => typography.display[props.size]};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

// Heading
type HeadingProps = TypographyProps & {
	size: HeadingType
}

const Heading = React.memo<HeadingProps>(
	({
		children,
		className,
		color,
		fontWeight,
		id,
		size,
		onClick,
		dataSal,
		dataSalDelay,
		dataSalDuration,
	}) => {
		const Tag = useMemo(() => {
			switch (size) {
				case 'xxlarge':
					return H1
				case 'xlarge':
					return H2
				case 'large':
					return H3
				case 'medium':
					return H4
				case 'small':
					return H5
				case 'xsmall':
					return H6
			}
		}, [size])

		return (
			<Tag
				className={className}
				color={color}
				fontWeight={useMemo(() => getWeightValue(fontWeight), [fontWeight])}
				id={id}
				onClick={onClick}
				data-sal={dataSal}
				data-sal-delay={dataSalDelay}
				data-sal-duration={dataSalDuration}
			>
				{children}
			</Tag>
		)
	}
)

const H1 = styled.h1<TagProps>`
	${typography.heading.xxlarge};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

const H2 = styled.h2<TagProps>`
	${typography.heading.xlarge};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

const H3 = styled.h3<TagProps>`
	${typography.heading.large};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

const H4 = styled.h4<TagProps>`
	${typography.heading.medium};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

const H5 = styled.h5<TagProps>`
	${typography.heading.small};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

const H6 = styled.h5<TagProps>`
	${typography.heading.xsmall};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

type BodyProps = TypographyProps & {
	size: BodyType
	component?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div' | 'li'
}

// body
const Body = React.memo<BodyProps>(
	({
		children,
		className,
		color,
		fontWeight,
		size,
		onClick,
		dataSal,
		dataSalDelay,
		dataSalDuration,
		component,
	}) => {
		return (
			<P
				size={size}
				className={className}
				color={color}
				fontWeight={useMemo(() => getWeightValue(fontWeight), [fontWeight])}
				onClick={onClick}
				data-sal={dataSal}
				data-sal-delay={dataSalDelay}
				data-sal-duration={dataSalDuration}
				as={component}
			>
				{typeof children === 'string'
					? children.split('\n').map((line, index, array) => (
							<span key={index}>
								{line}
								{array.length !== index + 1 && <br />}
							</span>
					  ))
					: children}
			</P>
		)
	}
)

const P = styled.p<
	TagProps & {
		size: BodyType
	}
>`
	${(props) => typography.body[props.size]};
	color: ${({ theme, color }) => color && theme.palette[color]};
	font-weight: ${(props) => props.fontWeight};
	white-space: pre-wrap;
`

export default { Display, Heading, Body }
