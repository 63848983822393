const palette = {
	dark100: '#000000',
	dark80: '#4d5c6a',
	dark60: '#98a4af',
	dark40: '#ccd1d7',
	dark20: '#e7ecef',
	dark10: '#f6f8fa',
	dark00: '#ffffff',
} as const

export default palette

export type PaletteType = keyof typeof palette
