import { css, FlattenSimpleInterpolation } from 'styled-components'

export type TypographyFontWeight = 'regular' | 'medium' | 'bold' | 'extrabold'

export type DisplayType = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small'

export type HeadingType =
	| 'xxlarge'
	| 'xlarge'
	| 'large'
	| 'medium'
	| 'small'
	| 'xsmall'
	| 'xxsmall'

export type BodyType = 'xlarge' | 'large' | 'medium' | 'small'

const display: { [key in DisplayType]: FlattenSimpleInterpolation } = {
	xxlarge: css`
		font-size: 6rem;
		line-height: 7rem;
	`,
	xlarge: css`
		font-size: 5rem;
		line-height: 6rem;
	`,
	large: css`
		font-size: 4.5rem;
		line-height: 5.5rem;
	`,
	medium: css`
		font-size: 4rem;
		line-height: 4.5rem;
	`,
	small: css`
		font-size: 3.5rem;
		line-height: 4rem;
	`,
} as const

const heading: { [key in HeadingType]: FlattenSimpleInterpolation } = {
	xxlarge: css`
		font-size: 3rem;
		line-height: 3.5rem;
	`,
	xlarge: css`
		font-size: 2.5rem;
		line-height: 3rem;
	`,
	large: css`
		font-size: 2.25rem;
		line-height: 2.75rem;
	`,
	medium: css`
		font-size: 2rem;
		line-height: 2.5rem;
	`,
	small: css`
		font-size: 1.75rem;
		line-height: 2.25rem;
	`,
	xsmall: css`
		font-size: 1.5rem;
		line-height: 2rem;
	`,
	xxsmall: css`
		font-size: 1.3rem;
		line-height: 2rem;
	`,
} as const

const body: { [key in BodyType]: FlattenSimpleInterpolation } = {
	xlarge: css`
		font-size: 1.5rem;
		line-height: 2.6666666666666665rem;
	`,
	large: css`
		font-size: 1.25rem;
		line-height: 1.75rem;
	`,
	medium: css`
		font-size: 1.125rem;
		line-height: 1.5rem;
	`,
	small: css`
		font-size: 0.875rem;
		line-height: 1.25rem;
	`,
} as const

const caption = css`
	font-size: 0.75rem;
	line-height: 1rem;
`

export const fontWeight = {
	regular: css`
		font-weight: 400;
	`,
	medium: css`
		font-weight: 500;
	`,
	bold: css`
		font-weight: 700;
	`,
} as const

export function getWeightValue(weight?: TypographyFontWeight) {
	switch (weight) {
		case 'regular':
			return 400
		case 'medium':
			return 500
		case 'bold':
			return 700
		case 'extrabold':
			return 800
		default:
			return 400
	}
}

const typography = { display, heading, body, caption }
export { display, heading, body, caption }
export default typography
