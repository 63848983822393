import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import Menu from '../../../assets/icons/menu.svg'
import MenuWhite from '../../../assets/icons/menu-white.svg'
import MobileMenu from '../mobileMenu/MobileMenu'

interface MenuButtonProps {
	blackBackground?: boolean
}

function MenuButton({ blackBackground }: MenuButtonProps) {
	const [isOpen, setIsOpen] = useState(false)
	const openMenu = useCallback(() => {
		setIsOpen(true)
	}, [])
	const closeMenu = useCallback(() => {
		setIsOpen(false)
	}, [])

	return (
		<>
			<Block onClick={openMenu}>
				{blackBackground === true ? <MenuWhite /> : <Menu />}
			</Block>
			{isOpen && <MobileMenu closeMenu={closeMenu} />}
		</>
	)
}

const Block = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	pointer-events: all;
	margin-bottom: 0.125rem;

	svg {
		width: 3.5rem;
		height: 3.5rem;
	}
`

export default MenuButton
