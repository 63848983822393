import { DefaultTheme } from 'styled-components'
import palette from './palette'
import breakpoints from './breakpoints'
import typography, { fontWeight } from './typography'

export const theme: DefaultTheme = {
	palette,
	typography,
	fontWeight,
	breakpoints,
}
