import { css } from 'styled-components'

export const fullWidth = css`
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	padding: 0 6.25px;
`

export const buttonHover = css`
	&:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}

	&:active {
		background-color: rgba(0, 0, 0, 0.7);
	}
`

export const animateBorderBottom = css`
	&::after {
		display: block;
		content: '';
		width: 0;
		height: 3px;
		background: #000;
		transition: width 0.2s ease-in-out;
	}

	&:hover::after {
		width: 100%;
	}
`

export const animateBorderBottomWhite = css`
	&::after {
		display: block;
		content: '';
		width: 0;
		height: 3px;
		background: #fff;
		transition: width 0.2s ease-in-out;
	}

	&:hover::after {
		width: 100%;
	}
`

export const animateFooterBorderBottomWhite = css`
	&::after {
		display: block;
		content: '';
		width: 0;
		height: 3px;
		background: #fff;
		transition: width 0.2s ease-in-out;
	}

	&:hover::after {
		width: 100%;
	}
`

export const animateFooterBorderBottomBlack = css`
	&::after {
		display: block;
		content: '';
		width: 0;
		height: 3px;
		background: #000;
		transition: width 0.2s ease-in-out;
	}

	&:hover::after {
		width: 100%;
	}
`
