import React from 'react'
import styled from 'styled-components'

function ConsultRequest() {
	const goToContact = () => {
		const isClient = typeof Window !== 'undefined'
		if (isClient === true) {
			const location = document.getElementById('DataVoucherContactPoint')
			location?.scrollIntoView({ behavior: 'smooth' })
		}
	}

	return <Button onClick={goToContact}>상담신청</Button>
}

export default ConsultRequest

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #0039ff;
	border-radius: 32px;
	gap: 8px;
	color: #ffffff;
	font-style: normal;

	@media (min-width: 1280px) {
		width: 120px;
		height: 56px;
		font-weight: 400;
		font-family: 'Inter', 'Pretendard';
		font-size: 20px;
		line-height: 32px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		width: 96px;
		height: 44px;
		font-family: 'Pretendard', 'Inter';
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}
	@media (max-width: 768px) {
		width: 89px;
		height: 36px;
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
	}
`
