import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

type MetaItem = JSX.IntrinsicElements['meta']

interface SEOProps {
	metaData: {
		title: string
		description: string
		keywords: string
	}
	meta?: MetaItem[]
	lang?: string
}

function SEO({ lang = 'ko', metaData }: SEOProps) {
	const { title, description, keywords } = metaData
	const data = useStaticQuery(
		graphql`
			query {
				favicon: file(relativePath: { eq: "images/favicon.ico" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				ogt: file(relativePath: { eq: "images/OGT.png" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`
	)

	const faviconUrl = data.favicon?.childImageSharp?.fluid?.src || '';
	const ogtUrl = data.ogt?.childImageSharp?.fluid?.src || '';

	return (
		<Helmet
			htmlAttributes={{
				language: lang,
			}}
		>
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link
				rel="preload"
				href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap"
				as="font"
				crossOrigin="anonymous"
			/>
			<link
				href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;700&display=swap"
				rel="stylesheet"
			></link>
			<link rel="canonical" href="https://www.tesser.io" />
			<link rel="shortcut icon" href={faviconUrl} type="image/x-icon" />
			<link rel="icon" href={faviconUrl} type="image/x-icon" />

			{/* SEO Meta tags */}
			<title>{title}</title>
			<meta name="google-site-verification" content="lGZRE2HXWlNM-cjH00pEgZbrhjc5Kcl1oCIGhPRQw74" />
			<meta name="description" content={description} data-react-helmet="true" />
			<meta name="keywords" content={keywords} />
			<meta
				name="naver-site-verification"
				content="2d222c6ff0f96d55bcd2629e581b22c01d69a151"
			/>
			<meta
				name="google-site-verification"
				content="mvbo4PZlXiHNoovkMegzeM1muZJsoaDUJeXZJCP9ii4"
			/>
			<meta property="og:title" content={title} />
			<meta property="og:url" content="https://www.tesser.io" />
			<meta property="og:type" content="website" />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={ogtUrl} />
			<meta property="og:site_name" content="테서" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		</Helmet>
	)
}

export default SEO
