import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { window, document } from 'browser-monads'

import Arrow from '../../../assets/icons/arrow.svg'
import { buttonHover } from '../../../lib/styles/utils'
import zIndexes from '../../../lib/styles/zIndexes'
import { down, only, up } from 'styled-breakpoints'
import container from '../../../lib/styles/container'
import useWindowSize from '../../../lib/hooks/useWindowSize'
import { ColorType } from '../../../context/InvertColorsContext'

function TopButton({ itemColor }) {
	const breakpoints = useBreakpoint()
	const documentRef = useRef(document)
	const [hide, setHide] = useState(false)
	const [pageY, setPageY] = useState(0)
	const windowSize = useWindowSize()

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const handleScroll = () => {
		const { pageYOffset } = window
		const deltaY = pageYOffset - pageY
		const hidden = pageYOffset !== 0 && deltaY >= 0
		setHide(hidden)
		setPageY(pageYOffset)
	}

	useEffect(() => {
		documentRef.current.addEventListener('scroll', handleScroll)
		return () => documentRef.current.removeEventListener('scroll', handleScroll)
	}, [pageY])

	const SCROLL_VALUE = windowSize.height ? windowSize.height / 3 : 50
	const isMobile = breakpoints.lg

	return (
		<Hide isMobile={isMobile} hide={hide || pageY < SCROLL_VALUE}>
			<ButtonWrapper onClick={scrollTop} itemColor={itemColor}>
				<Arrow />
			</ButtonWrapper>
		</Hide>
	)
}

const Hide = styled.div<{ hide: boolean; isMobile: boolean }>`
	position: fixed;
	bottom: 4.5rem;
	transition: all 0.5s;
	z-index: ${zIndexes.topButton};

	${up('xl')} {
		right: calc((100% - ${container.xl}) / 2 - 7rem);
	}
	${only('lg')} {
		right: calc((100% - ${container.lg}) / 2 - 7rem);
	}
	${only('md')} {
		right: calc((100% - ${container.md}) / 2 + 0.625rem);
	}
	${only('sm')} {
		right: calc((100% - ${container.sm}) / 2 + 1.25rem);
		bottom: 3.5rem;
	}
	${down('xs')} {
		right: 0;
		transform: translateX(-28px);
		bottom: 3rem;
	}

	${(props) =>
		props.hide &&
		css`
			opacity: 0;
		`}
`

const ButtonWrapper = styled.div<{ itemColor: ColorType }>`
	width: 5rem;
	height: 5rem;
	background-color: ${(props) =>
		props.itemColor === ColorType.BLACK ? 'black' : 'white'};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 1;
	cursor: pointer;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));

	${(props) =>
		props.itemColor === ColorType.WHITE &&
		css`
			svg {
				path {
					fill: black;
				}
			}
		`};

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	${buttonHover};
`

export default TopButton
