/* eslint-disable no-undef */
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: { order: ['localStorage', 'navigator'] },
		fallbackLng: 'ko',
		resources: {
			ko: {
				translations: require('../locales/kr/translations.json'),
			},
			en: {
				translations: require('../locales/en/translations.json'),
			},
			'"ko"': {
				translations: require('../locales/kr/translations.json'),
			},
			'"en"': {
				translations: require('../locales/en/translations.json'),
			},
			'ko-KR': {
				translations: require('../locales/kr/translations.json'),
			},
			'en-US': {
				translations: require('../locales/en/translations.json'),
			},
			'"ko-KR"': {
				translations: require('../locales/kr/translations.json'),
			},
			'"en-US"': {
				translations: require('../locales/en/translations.json'),
			},
		},
		ns: ['translations'],
		defaultNS: 'translations',
		returnObjects: true,
		debug: process.env.NODE_ENV === 'development',
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
		react: {
			useSuspense: true,
		},
	})

i18next.languages = [
	'ko-KR',
	'en-US',
	'"ko-KR"',
	'"en-US"',
	'ko',
	'en',
	'"ko"',
	'"en"',
]

export default i18next
