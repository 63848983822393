import React from 'react'
import styled from 'styled-components'
import { up, down, only } from 'styled-breakpoints'
import container from '../../lib/styles/container'

export type ResponsiveProps = {
	className?: string
	children: React.ReactNode
	isDataVoucher?: boolean
}

function Responsive({ className, children, isDataVoucher }: ResponsiveProps) {
	return isDataVoucher ? (
		<DataVoucherContainer className={className}>
			{children}
		</DataVoucherContainer>
	) : (
		<Block className={className}>{children}</Block>
	)
}

const Block = styled.div`
	max-width: ${container.maxWidth};
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 100%;

	${up('xl')} {
		max-width: ${container.xl};
	}
	${only('lg')} {
		max-width: ${container.lg};
	}
	${only('md')} {
		max-width: ${container.md};
	}
	${only('sm')} {
		max-width: ${container.sm};
	}
	${down('xs')} {
		padding: 20px;
		padding-bottom: 0;
	}
`

const DataVoucherContainer = styled.div`
	max-width: ${container.maxWidth};
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	display: flex;
	justify-content: center;
`

export default Responsive
