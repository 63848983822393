const breakpoints = {
	xxs: '0px',
	xs: '576px',
	sm: '768px',
	md: '1024px',
	lg: '1440px',
	xl: '1920px',
} as const

export default breakpoints

export type BreakpointsType = keyof typeof breakpoints
