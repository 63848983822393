import { createGlobalStyle } from 'styled-components'
import { up, down, only } from 'styled-breakpoints'

const GlobalStyles = createGlobalStyle`
html{
  height: 100%; 
  position: relative;   
  overflow-x: hidden;
 
	${up('xl')} {
    font-size: 16px;
	}
	${only('lg')} {
    font-size: 15px; 
	}
	${only('md')} {
    font-size: 14px; 
	}
	${only('sm')} {
    font-size: 12px;
	}
	${down('xs')} {
    font-size: 11px;
	}
} 

body  {
  min-height: 100%;
  display: flex;
  flex-direction: column;    
  overflow-x: hidden;
}

#root{ 
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 100%;
}

body {
  box-sizing: border-box;
  font-family: 'Inter', -apple-system, 'Noto Sans KR', BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #ffffff; 
}

* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

input, button, textarea {
  font-family: inherit;
}

ul, li {
  margin: 0;
  padding: 0;
}

button {
  /* (all: unset) Safari bug: https://stackoverflow.com/questions/61019987/cant-set-text-color-of-all-unset-button-in-safari */
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	user-select: none;
  cursor: pointer;
}

a {
  all: unset;
  cursor: pointer;
	user-select: none;
  :visited {
    color: inherit;
  }
}
.simplebar-track.simplebar-vertical{
  width: 9px;
}
.simplebar-scrollbar:before{
  background: #98a4af;
}
/* .simplebar-scrollbar.simplebar-visible:before{
  opacity: 0.3;
} */

.no-scroll{
  overflow: hidden;
}
.no-scroll-y{
  overflow-y: hidden;
  height: 100%;
  & > #root{
    height: 100%;
    & > div{
      height: 100%;
    }
  }
}
`

export default GlobalStyles
