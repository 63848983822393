const zIndexes = {
	gradient: -1,
	shapeBox: -1,
	triangleShadow: -1,
	solutionIntroSection: -1,
	solutionIntroBackText: -1,
	solutionIntroImage: -1,
	medicalIntroText: 1,
	mainGlobalText: 2,
	productCardContent: 2,
	medicalSecondSection: 3,
	globeSection: 1,
	visionSection: 2,
	partners: 10,
	leftBar: 1000,
	topButton: 1000,
	shapeAnimation: 100,
	medicalCTA: 99,
	footer: 998,
	header: 999,
	mainIntroShape: 9999,
	mobileMenu: 9999,
	topBanner: 1000,
} as const

export default zIndexes
