import React, { useEffect } from 'react'
import { down, only } from 'styled-breakpoints'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'

import LinkIcon from '../../assets/icons/link.svg'
import ClippedLogo from '../../assets/svg/clipped-logo.svg'
import Logo from '../../assets/svg/logo.svg'
import Typography from '../common/Typography'
import Responsive from './Responsive'
import zIndexes from '../../lib/styles/zIndexes'
import {
	animateFooterBorderBottomWhite,
	animateFooterBorderBottomBlack,
} from '../../lib/styles/utils'
import { ColorType } from '../../context/InvertColorsContext'
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth'
import { navigatorLinks } from '../../lib/data/navigatorLinks'
interface FooterProps {
	invertColors: (colorType: ColorType) => void
	blackFooter?: boolean
	medicalPage?: boolean
	isInfo?: boolean
	isBrowswerSafariNotMobile?: boolean
}

function Footer({
	invertColors,
	blackFooter,
	medicalPage,
	isInfo,
	isBrowswerSafariNotMobile,
}: FooterProps) {
	const isClient = typeof Window !== 'undefined'
	let url = ''

	const [ref, inView] = useInView({
		threshold: 0.5,
	})

	if (isClient === true) {
		url = window.location.pathname
	}

	useEffect(() => {
		if (!blackFooter) {
			return () => {
				invertColors(ColorType.WHITE)
			}
		}

		if (blackFooter && !medicalPage) {
			if (inView === true) {
				invertColors(ColorType.BLACK)
			}
			if (inView === false) {
				invertColors(ColorType.WHITE)
			}
		}

		if (blackFooter && medicalPage) {
			if (inView === true) {
				invertColors(ColorType.BLACK)
			}
		}
	}, [inView])

	const { t } = useTranslation()
	return (
		<Block blackFooter={blackFooter} ref={ref}>
			<FooterContent>
				<LeftSide>
					{blackFooter ? (
						isBrowswerSafariNotMobile ? (
							<LogoTextForSafari>TESSER</LogoTextForSafari>
						) : (
							<div className="logoFilter">{<ClippedLogo />}</div>
						)
					) : (
						<Logo />
					)}
				</LeftSide>
				<SiteMapWrapper>
					<SiteMapGroup
						title={t('footer.title1')}
						list={[
							{
								text: t('footer.text5'),
								link: 'https://blog.tesser.io',
								blank: true,
							},
							{
								text: t('footer.text4'),
								link: 'https://www.instagram.com/tesserdata/',
								blank: true,
							},
							{
								text: t('footer.text2'),
								link: 'https://www.facebook.com/tesserinc/',
								blank: true,
							},
							// {
							// 	text: t('footer.text1'),
							// 	link: 'https://www.linkedin.com/company/tesser-inc/',
							// },
							// {
							// 	text: t('footer.text3'),
							// 	link: 'https://twitter.com/tesserinc',
							// },

							// {
							// 	text: t('footer.text5'),
							// 	link: 'https://medium.com/@tesser-ai',
							// },
						]}
						blackFooter={blackFooter}
					/>
					<SiteMapGroup
						title={t('footer.title2')}
						list={[
							{
								text: t('footer.text6'),
								link: 'https://ontol.com/',
								blank: true,
							},
							{
								text: t('footer.text7'),
								link: '/product#ontol-for-clinics',
								blank: false,
							},
							{
								text: t('footer.text8'),
								link: navigatorLinks.technology,
								blank: false,
							},
						]}
						blackFooter={blackFooter}
					/>
					<SiteMapGroup
						title={t('footer.title3')}
						list={[
							{
								text: t('footer.text11'),
								link: navigatorLinks.news,
								blank: true,
							},
							{
								text: t('footer.text9'),
								link: navigatorLinks.contact,
								blank: false,
							},
							{
								text: t('footer.text10'),
								link: 'https://goo.gl/maps/n9zWkuqoDH8LA9QK9',
								blank: true,
							},
						]}
						blackFooter={blackFooter}
					/>
				</SiteMapWrapper>
			</FooterContent>
			{isInfo === true ? (
				<TesserInfo blackFooter={blackFooter}>
					{t('footer.corpInfo')}
				</TesserInfo>
			) : (
				''
			)}
		</Block>
	)
}

const TesserInfo = styled(Responsive)<{ blackFooter?: boolean }>`
	padding-bottom: 5rem;
	font-size: 12px;
	line-height: 16px;
	color: #424242;

	${(props) =>
		props.blackFooter &&
		css`
			color: #a6a4a4;
		`}
`

const Block = styled.div<{ blackFooter?: boolean }>`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	z-index: ${zIndexes.footer};
	overflow: hidden;
	background-color: white;
	color: black;
	margin-top: auto;
	flex-direction: column;

	${(props) =>
		props.blackFooter &&
		'white' &&
		css`
			background-color: black;
			color: white;
		`}
`

const FooterContent = styled(Responsive)`
	margin: 0 auto;
	display: flex;
	height: 100%;
	position: relative;
	padding: 7.5rem 3.5rem;
	padding-bottom: 7.5rem;
	justify-content: space-between;

	${down('sm')} {
		flex-direction: column;
		align-items: flex-start;
	}

	${down('xs')} {
		padding: 5rem 20px;
	}
`

const LeftSide = styled.section`
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	max-width: ${calculateGridWidth(3)};

	${only('md')} {
		margin-right: 2rem;
		min-width: ${calculateGridWidth(2, true)};
	}
	${only('sm')} {
		width: ${calculateGridWidth(2, true)};
		height: 120px;
	}
	${down('xs')} {
		width: calc(50% - 16px);
		max-width: none;
		height: 100px;
	}

	.logoFilter {
		min-width: 144px;
		width: calc(100% + 30px);
		background: linear-gradient(
			-210deg,
			rgba(255, 255, 255, 0.1),
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.4)
		);
		background-size: 200% 200%;
		animation: gradient 7s ease infinite;
		clip-path: url('#logo');
		transform: scale(0.9) translate(-16px);

		${down('lg')} {
		}
		${down('md')} {
			width: calc(100% + 100px);
			transform: scale(0.67) translate(-74px);
		}
		${down('xs')} {
			width: calc(100% + 200px);
			transform: scale(0.55) translate(-40%);
		}
		svg {
			width: 100%;
		}
	}

	svg {
		max-width: 20vw;
		width: 100%;
		min-width: 144px;

		${only('md')} {
		}
		${down('sm')} {
			margin-bottom: 40px;
			max-width: none;
		}
	}
	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`

const LogoTextForSafari = styled.div`
	font-size: 50px;
	font-weight: 800;
`

const SiteMapWrapper = styled.section`
	display: flex;
	height: 100%;
	justify-content: space-between;
	width: ${calculateGridWidth(9)};

	${down('lg')} {
		width: ${calculateGridWidth(8)};
	}
	${down('md')} {
		width: 100%;
		padding-top: 16px;
		padding-bottom: 5rem;
		width: ${calculateGridWidth(6, true)};
	}
	${down('sm')} {
		width: 100%;
		padding-top: 0;
	}

	${down('xs')} {
		flex-wrap: wrap;

		> * {
			margin: 2rem;
		}
	}
`

interface listItemTypes {
	text: string
	link: string
	blank: boolean
}

const SiteMapGroup = ({
	list,
	title,
	blackFooter,
}: {
	list: Array<listItemTypes>
	title: string
	blackFooter?: boolean
}) => {
	return (
		<SiteMapBlock>
			<Typography.Body size="small" fontWeight="bold" className="subtitle">
				{title}
			</Typography.Body>
			{list.map(({ text, link, blank }: listItemTypes) => (
				<LinkItem key={text}>
					{blank ? (
						<a href={link} target="_blank">
							<LinkWrapper blackFooter={blackFooter}>
								<Typography.Heading
									size="large"
									fontWeight="bold"
									className="text"
								>
									{text}
								</Typography.Heading>
								<LinkIcon />
							</LinkWrapper>
						</a>
					) : (
						<Link to={link}>
							<LinkWrapper blackFooter={blackFooter}>
								<Typography.Heading
									size="large"
									fontWeight="bold"
									className="text"
								>
									{text}
								</Typography.Heading>
								<LinkIcon />
							</LinkWrapper>
						</Link>
					)}
				</LinkItem>
			))}
		</SiteMapBlock>
	)
}

const SiteMapBlock = styled.div`
	width: ${calculateGridWidth(3)};
	${down('lg')} {
		width: ${calculateGridWidth(3)};
		margin-left: 0;
	}
	${only('md')} {
		&:nth-of-type(2) {
			.text {
				flex: 0;
				word-break: keep-all;
				> * {
					width: 100%;
				}
			}
		}
	}
	${down('md')} {
		width: calc(33.33% - 16px);
	}
	${down('sm')} {
		margin-left: 0;
		margin-right: 0;
		width: ${calculateGridWidth(2, true)};
	}
	${down('xs')} {
		max-width: none;
		width: calc(50% - 16px);
	}

	.subtitle {
		margin-bottom: 0.5rem;
		opacity: 0.5;

		${down('sm')} {
			font-size: 14px;
		}
		${down('xs')} {
			font-size: 12px;
		}
	}
`

const LinkItem = styled.span`
	display: flex;
	${only('md')} {
		&:nth-of-type(2) {
			.text {
				flex: 1;
			}
		}
	}
	& + & {
		margin-top: 0.5rem;
	}
`

const LinkWrapper = styled.div<{ blackFooter?: boolean }>`
	display: flex;
	cursor: pointer;

	.text {
		transition: all 0.2s;
		${animateFooterBorderBottomBlack};
		/* font-size: 24px; */
		${down('sm')} {
			font-size: 20px;
		}
		${down('xs')} {
			font-size: 18px;
		}
	}

	svg {
		opacity: 0.5;
		width: 0.5rem;
		height: 0.5rem;
		margin-left: 0.25rem;
		transition: all 0.2s;
		${only('md')} {
			/* flex: 0 0 auto; */
			/* position: absolute; */
		}
		path {
			fill: black;
		}
	}

	${(props) =>
		props.blackFooter &&
		css`
			.text {
				${animateFooterBorderBottomWhite};
			}

			svg {
				opacity: 0.5;
				width: 0.5rem;
				height: 0.5rem;
				margin-left: 0.25rem;
				transition: all 0.2s;
				path {
					fill: white;
				}
			}
		`}

	&:hover {
		svg {
			opacity: 1;
		}
	}
`

export default Footer
