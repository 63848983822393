export const GUTTER = 32
export const COLUMN_WIDTH_SMALL_SCREEN = 88
export const COLUMN_WIDTH_LARGE_SCREEN = 72

export const calculateGridWidth = (column: number, small?: boolean) => {
	const COLUMN_WIDTH = small ? COLUMN_WIDTH_SMALL_SCREEN : COLUMN_WIDTH_LARGE_SCREEN

	const gridWidth = column * COLUMN_WIDTH + (column - 1) * GUTTER

	return `${gridWidth}px`
}
