import React from 'react'
import { GatsbyLinkProps, Link } from 'gatsby'

type LinkProps = GatsbyLinkProps<unknown> &
	React.DetailedHTMLProps<
		React.AnchorHTMLAttributes<HTMLAnchorElement>,
		HTMLAnchorElement
	>

const LinkWrapper = ({ to, state, target, children, ...props }: LinkProps) => {
	const isInternalLink = to.startsWith('/')
	if (isInternalLink) {
		return (
			<Link to={to} state={state} target={target}>
				{children}
			</Link>
		)
	}
	return (
		<a href={to} target={target} rel="noopener noreferrer" {...props}>
			{children}
		</a>
	)
}

export default LinkWrapper
