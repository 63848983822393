import React, { useEffect, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { down } from 'styled-breakpoints'
import { globalHistory } from '@reach/router'

import Logo from '../../../assets/svg/logo.svg'
import CloseIcon from '../../../assets/icons/close.svg'
import Typography from '../../common/Typography'
import zIndexes from '../../../lib/styles/zIndexes'
import Portal from './Portal'
import { navigatorLinks } from '../../../lib/data/navigatorLinks'
import { animateBorderBottom } from '../../../lib/styles/utils'
import storage from '../../../lib/utils/storage'
import { modifyLangString } from '../../../lib/utils/modifyLangString'

interface MobileMenuProps {
	closeMenu: () => void
}

function MobileMenu({ closeMenu }: MobileMenuProps) {
	const { i18n } = useTranslation()
	const initialLanguage =
		modifyLangString(i18n.language) ?? storage.getItem('language')
	const [language, setLanguage] = useState(initialLanguage)

	const isClient = typeof Window !== 'undefined'
	let url = ''

	if (isClient === true) {
		url = window.location.pathname
	}

	const changeKor = () => {
		setLanguage('ko')
		i18n.changeLanguage('ko')
		localStorage.setItem('language', 'ko')
	}
	const changeEng = () => {
		setLanguage('en')
		i18n.changeLanguage('en')
		localStorage.setItem('i18nextLng', 'en')
	}

	useEffect(() => {
		document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`
		return () => {
			const scrollY = document.body.style.top
			document.body.style.cssText = `position: ""; top: "";`
			window.scrollTo(0, parseInt(scrollY || '0') * -1)
		}
	}, [])

	const setScreenSize = useCallback(() => {
		const vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}, [])

	useEffect(() => {
		window.addEventListener('resize', setScreenSize)
		setScreenSize()

		return () => {
			window.removeEventListener('resize', setScreenSize)
		}
	}, [])

	return (
		<Portal elementId="modal-root">
			<Block onClick={closeMenu}>
				<Background>
					<MenuHeader>
						<span className="logo">
							<Link to="/">
								<Logo />
							</Link>
						</span>
						<span className="close" onClick={closeMenu}>
							<CloseIcon />
						</span>
					</MenuHeader>
					<Navigation />
					<MenuFooter>
						<CopyRight>
							<Typography.Body size="small">
								&copy; 2024 Tesser inc.
							</Typography.Body>
							<Typography.Body size="small">
								All Rights Reserved
							</Typography.Body>
						</CopyRight>
						<LanguageOption language={language}>
							<Typography.Heading
								size="xsmall"
								className="kor"
								onClick={changeKor}
							>
								KOR
							</Typography.Heading>
							<Typography.Heading
								size="xsmall"
								className="eng"
								onClick={changeEng}
							>
								ENG
							</Typography.Heading>
						</LanguageOption>
					</MenuFooter>
				</Background>
			</Block>
		</Portal>
	)
}

const Block = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	min-height: calc(var(--vh, 1vh) * 100);
	z-index: ${zIndexes.mobileMenu};
	background-color: white;
	/* overflow-y:scroll; */
`

const Background = styled.div`
	width: 100%;
	height: 100%;
	background-color: white;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const MenuHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	> span {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logo {
		svg {
			max-width: 10rem;
		}
	}

	.close {
		cursor: pointer;
	}
`

const MenuFooter = styled.div`
	display: flex;
	justify-content: space-between;
`

const CopyRight = styled.div``

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LanguageOption = styled.div<{ language: any }>`
	cursor: pointer;
	display: flex;
	position: relative;
	z-index: 999999;

	${(props) =>
		props.language === 'ko' &&
		css`
			.kor {
				border-bottom: 1px solid black;
				margin-right: 1.5rem;
			}

			.eng {
				color: #bfc6cd;
			}
		`}
	${(props) =>
		props.language === 'en' &&
		css`
			.kor {
				color: #bfc6cd;
				margin-right: 1.5rem;
			}

			.eng {
				border-bottom: 1px solid black;
			}
		`}
`

function Navigation() {
	const isActive = useCallback(
		(currentPage: string) => {
			const [, pathname] = globalHistory.location.pathname.split('/')
			const [, pagename] = currentPage.split('/')

			return pathname === pagename ? true : false
		},
		[globalHistory.location.pathname]
	)
	const { t } = useTranslation()
	// const { t, i18n } = useTranslation()
	// const initialLanguage = localStorage.getItem('language')
	// 	? localStorage.getItem('language')
	// 	: 'ko'
	const navigation = [
		{ name: t('header.header1'), link: navigatorLinks.about },
		{ name: t('header.header2'), link: navigatorLinks.product },
		{ name: t('header.header3'), link: navigatorLinks.technology },
		{ name: t('header.header4'), link: navigatorLinks.career },
		// ...(i18n.language == 'en' && initialLanguage === 'en'
		// 	? []
		// 	: [{ name: t('header.header5'), link: navigatorLinks.labeling }]),
		{ name: t('header.header6'), link: navigatorLinks.contact },
	]
	return (
		<NavBlock>
			<ul>
				{navigation.map(({ name, link }) => {
					const active = link !== navigatorLinks.career && isActive(link)
					if (name === 'Data Voucher') {
						return
					}
					return (
						<NavItem key={name} active={active}>
							<Link to={link}>
								<Typography.Display
									size="xxlarge"
									fontWeight="bold"
									className="navItem"
								>
									{name}
								</Typography.Display>
							</Link>
						</NavItem>
					)
				})}
			</ul>
		</NavBlock>
	)
}

const NavBlock = styled.nav`
	display: flex;
	align-items: center;

	> ul {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
`

const NavItem = styled.li<{ active: boolean }>`
	white-space: nowrap;
	list-style: none;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-right: 0.5rem;

	.navItem {
		${down('xs')} {
			font-size: 48px;
			line-height: 56px;
			font-weight: 700;
		}
	}

	${animateBorderBottom}

	${(props) =>
		props.active &&
		css`
			&::after {
				display: block;
				content: '';
				height: 0.375rem;
				background: #000;
				transition: width 0.2s ease-in-out;
				width: 100%;
			}
		`}
`

export default MobileMenu
